var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "header",
        {
          staticClass: "overflow-hidden mb-4 rounded-t-0",
          staticStyle: { "border-radius": "46px" },
        },
        [
          _c(
            "v-img",
            {
              staticClass: "white--text align-end",
              attrs: {
                height: "250px",
                "lazy-src": require("@/assets/images/placeholder.png"),
                src: _vm.clinic.coverPic
                  ? _vm.clinic.coverPic
                  : require("@/assets/images/placeholder.png"),
              },
            },
            [
              _c(
                "v-avatar",
                {
                  staticClass: "mb-2 ms-2 me-0",
                  attrs: { color: "grey", size: "92" },
                },
                [
                  _c("v-img", {
                    staticClass: "border border-white border-4",
                    attrs: {
                      "lazy-src": require("@/assets/images/profile-circle.png"),
                      src: _vm.clinic.profilePic
                        ? _vm.clinic.profilePic
                        : require("@/assets/images/placeholder.png"),
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-container",
        { attrs: { fluid: "" } },
        [
          _c("span", { staticClass: "d-block f18 font-weight-bold" }, [
            _vm._v(_vm._s(_vm.clinic.name)),
          ]),
          _c("p", {
            staticClass: "f14 my-2",
            domProps: { innerHTML: _vm._s(_vm.clinic.about) },
          }),
          _c(
            "a",
            {
              staticClass: "d-block f15 primary--text mt-2 ltr",
              attrs: { href: _vm.clinic.link, target: "_blank" },
            },
            [_vm._v(_vm._s(_vm.clinic.link))]
          ),
          _c(
            "v-btn",
            {
              staticClass: "mt-4 text-center f15 white--text rounded-lg",
              attrs: { color: "green", block: "" },
              on: {
                click: function ($event) {
                  return _vm.openCallBottomSheet()
                },
              },
            },
            [_vm._v(" " + _vm._s(_vm.$t("Call")) + " ")]
          ),
        ],
        1
      ),
      _c(
        "v-container",
        { attrs: { fluid: "" } },
        [
          _vm.questions && _vm.questions.length > 0
            ? _c(
                "div",
                { staticClass: "d-flex justify-content-between mt-4" },
                [
                  _c("div", [
                    _c("span", { staticClass: "16 font-weight-bold" }, [
                      _vm._v(_vm._s(_vm.$t("Answered questions"))),
                    ]),
                  ]),
                  _c(
                    "div",
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "primary--text pa-1",
                          attrs: {
                            color: "primary",
                            depressed: "",
                            plain: "",
                            link: "",
                            "min-width": "auto",
                            "min-height": "auto",
                            height: "auto",
                            to: `/s/clinic/${_vm.$route.params.clinicId}/questions`,
                          },
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("Show all")) + " ")]
                      ),
                    ],
                    1
                  ),
                ]
              )
            : _vm._e(),
          _vm.questions && _vm.questions.length > 0
            ? _c(
                "div",
                { staticClass: "mt-2" },
                [
                  _c(
                    "v-carousel",
                    {
                      attrs: {
                        height: "auto",
                        cycle: "",
                        "hide-delimiter-background": "",
                        "show-arrows": false,
                        interval: 4000,
                        light: "",
                      },
                    },
                    _vm._l(_vm.questions, function (item, i) {
                      return _c(
                        "v-carousel-item",
                        { key: i },
                        [
                          _c(
                            "v-card",
                            {
                              staticClass:
                                "mx-2 mt-2 mb-12 d-flex flex-column justify-space-between border",
                              attrs: {
                                elevation: "0",
                                hover: "",
                                ripple: "",
                                to: `/s/questions/${item.id}`,
                              },
                            },
                            [
                              _c(
                                "v-card-title",
                                {
                                  staticClass:
                                    "d-flex justify-space-between align-items-center",
                                },
                                [
                                  item.answers
                                    ? _c(
                                        "span",
                                        {
                                          staticClass:
                                            "blue lighten-5 blue--text text--darken-1 p-2 rounded-lg f12",
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(item.answers.length) +
                                              " " +
                                              _vm._s(_vm.$t("Answer"))
                                          ),
                                        ]
                                      )
                                    : _c(
                                        "span",
                                        {
                                          staticClass:
                                            "blue lighten-5 blue--text text--darken-1 p-2 rounded-lg f12",
                                        },
                                        [
                                          _vm._v(
                                            " 0 " + _vm._s(_vm.$t("Answer"))
                                          ),
                                        ]
                                      ),
                                  _c(
                                    "span",
                                    { staticClass: "f13 font-weight-light" },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm._f("moment")(
                                              item.createDate,
                                              "from",
                                              "now"
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                              _c("v-card-text", [
                                _c("p", {
                                  staticClass: "f14 text-justify",
                                  domProps: {
                                    innerHTML: _vm._s(item.question),
                                  },
                                }),
                              ]),
                              _c("v-divider", {
                                staticClass: "m-0",
                                attrs: { dark: "" },
                              }),
                              _c(
                                "v-card-actions",
                                {
                                  staticClass:
                                    "d-flex justify-space-between pt-2 align-center",
                                },
                                [
                                  item.answers && item.answers.length
                                    ? _c("div", [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "d-flex flex-row align-items-center",
                                          },
                                          [
                                            _c(
                                              "section",
                                              {
                                                staticClass:
                                                  "d-flex flex-row avatar-group right-to-left",
                                              },
                                              _vm._l(
                                                item.answers.slice(0, 3),
                                                function (answer, index) {
                                                  return _c(
                                                    "span",
                                                    {
                                                      key: index,
                                                      staticClass:
                                                        "avatars-group__item avatar",
                                                    },
                                                    [
                                                      _c(
                                                        "v-avatar",
                                                        {
                                                          staticClass:
                                                            "bordered",
                                                          attrs: {
                                                            size: "36",
                                                            color: "primary",
                                                          },
                                                        },
                                                        [
                                                          answer.doctorProfilePic &&
                                                          index < 2
                                                            ? _c("img", {
                                                                attrs: {
                                                                  src: answer.doctorProfilePic,
                                                                  alt: answer.doctorFullName
                                                                    ? answer.doctorFullName
                                                                    : "",
                                                                },
                                                              })
                                                            : index < 2
                                                            ? _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "white--text f12",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        answer.doctorFullName
                                                                          ? answer.doctorFullName.slice(
                                                                              0,
                                                                              1
                                                                            )
                                                                          : ""
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                ]
                                                              )
                                                            : _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "white--text f14",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " ... "
                                                                  ),
                                                                ]
                                                              ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                }
                                              ),
                                              0
                                            ),
                                            _c("div", [
                                              item.answers.length == 1
                                                ? _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "f12 font-weight-normal ps-1",
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            item.answers[0]
                                                              .doctorFullName
                                                              ? item.answers[0]
                                                                  .doctorFullName
                                                              : ""
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              item.answers.length > 1
                                                ? _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "f12 font-weight-normal ps-1",
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            item.answers.length
                                                          ) +
                                                          " " +
                                                          _vm._s(
                                                            _vm.$t("specialist")
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ]),
                                          ]
                                        ),
                                      ])
                                    : _c("div", [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "bg-harmonies text-paris-pink p-2 rounded-lg f12",
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(_vm.$t("Not answered")) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ]),
                                  _c("div", [
                                    _c(
                                      "div",
                                      { staticClass: "text-left mt-1" },
                                      [
                                        item.userLikeQuestions &&
                                        !item.userLikeQuestions.find(
                                          (x) =>
                                            x.mobile ==
                                            _vm.$store.getters.mobileNumber
                                        )
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "d-flex d-inline-flex align-items-center border border-primary rounded primary--text",
                                              },
                                              [
                                                _c("iconly", {
                                                  staticClass:
                                                    "ms-1 me-0 wh-20",
                                                  attrs: {
                                                    type: "bold",
                                                    name: "heart",
                                                  },
                                                }),
                                                item.userLikeQuestions &&
                                                item.userLikeQuestions.length
                                                  ? _c(
                                                      "span",
                                                      {
                                                        staticClass: "me-1 f12",
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              item
                                                                .userLikeQuestions
                                                                .length
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    )
                                                  : _c(
                                                      "span",
                                                      {
                                                        staticClass: "me-1 f12",
                                                      },
                                                      [_vm._v("0")]
                                                    ),
                                              ],
                                              1
                                            )
                                          : _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "d-flex d-inline-flex align-items-center primary rounded white--text",
                                              },
                                              [
                                                _c("iconly", {
                                                  staticClass:
                                                    "ms-1 me-0 wh-20",
                                                  attrs: {
                                                    type: "bold",
                                                    name: "heart",
                                                    color: "white",
                                                  },
                                                }),
                                                item.userLikeQuestions &&
                                                item.userLikeQuestions.length
                                                  ? _c(
                                                      "span",
                                                      {
                                                        staticClass: "me-1 f12",
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              item
                                                                .userLikeQuestions
                                                                .length
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    )
                                                  : _c(
                                                      "span",
                                                      {
                                                        staticClass: "me-1 f12",
                                                      },
                                                      [_vm._v("0")]
                                                    ),
                                              ],
                                              1
                                            ),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "d-flex d-inline-flex align-items-center border border-primary rounded primary--text ms-2",
                                          },
                                          [
                                            _c("iconly", {
                                              staticClass: "ms-1 me-0 wh-20",
                                              attrs: {
                                                type: "bold",
                                                name: "show",
                                              },
                                            }),
                                            _c(
                                              "span",
                                              { staticClass: "me-1 f12" },
                                              [_vm._v(_vm._s(item.view))]
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                  ]),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    }),
                    1
                  ),
                ],
                1
              )
            : _c("span", { staticClass: "text-center f16 d-block mt-8" }, [
                _vm._v(" " + _vm._s(_vm.$t("No results found")) + " "),
              ]),
          _c("div", { staticClass: "d-flex justify-content-between" }, [
            _c("div", [
              _c("span", { staticClass: "16 font-weight-bold" }, [
                _vm._v(_vm._s(_vm.$t("Latest posts"))),
              ]),
            ]),
            _c(
              "div",
              [
                _c(
                  "v-btn",
                  {
                    staticClass: "primary--text pa-1",
                    attrs: {
                      color: "primary",
                      depressed: "",
                      plain: "",
                      link: "",
                      "min-width": "auto",
                      "min-height": "auto",
                      height: "auto",
                      to: `/s/clinic/${_vm.$route.params.clinicId}/contents`,
                    },
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("Show all")) + " ")]
                ),
              ],
              1
            ),
          ]),
          _vm.posts && _vm.posts.length > 0
            ? _c(
                "v-row",
                _vm._l(_vm.posts, function (post, index) {
                  return _c(
                    "v-col",
                    {
                      key: index,
                      attrs: {
                        xs: "6",
                        sm: "6",
                        md: "4",
                        lg: "3",
                        xl: "3",
                        cols: "6",
                      },
                    },
                    [
                      _c(
                        "v-card",
                        {
                          staticClass: "rounded-lg",
                          attrs: {
                            to: `/s/contents/${post.type.toLowerCase()}/${
                              post.id
                            }`,
                          },
                        },
                        [
                          _c(
                            "v-img",
                            {
                              staticClass:
                                "d-flex align-items-center justify-content-center",
                              attrs: {
                                "lazy-src": require("@/assets/images/placeholder.png"),
                                src: post.picture,
                                "aspect-ratio": 1 / 1,
                              },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "text-white w-100 text-center" },
                                [
                                  post.type == "VIDEO"
                                    ? _c("i", {
                                        staticClass:
                                          "fas fa-play p-4 bg-black-50 rounded-pill",
                                      })
                                    : _vm._e(),
                                ]
                              ),
                            ]
                          ),
                          _c(
                            "v-card-title",
                            { staticClass: "f13 single-line pb-0" },
                            [_vm._v(" " + _vm._s(post.title) + " ")]
                          ),
                          _c(
                            "v-card-actions",
                            {
                              staticClass: "pt-0",
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.openDrBottomSheet(post.publisher)
                                },
                              },
                            },
                            [
                              _c(
                                "v-list-item",
                                {
                                  staticClass: "p-0",
                                  attrs: { "two-line": "" },
                                },
                                [
                                  _c(
                                    "v-list-item-avatar",
                                    [
                                      _c("v-img", {
                                        attrs: {
                                          "lazy-src": require("@/assets/images/profile-circle.png"),
                                          src: post.publisherPic,
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-list-item-content",
                                    [
                                      _c(
                                        "v-list-item-title",
                                        {
                                          staticClass:
                                            "f12 font-weight-bold primary--text",
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(post.publisherName) + " "
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "v-list-item-subtitle",
                                        { staticClass: "f10" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm._f("moment")(
                                                post.createDate,
                                                "from",
                                                "now"
                                              )
                                            )
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                }),
                1
              )
            : _c("span", { staticClass: "text-center f16 d-block mt-8" }, [
                _vm._v(" " + _vm._s(_vm.$t("No results found")) + " "),
              ]),
        ],
        1
      ),
      _c(
        "vue-bottom-sheet",
        { ref: "callBottomSheet", attrs: { "swipe-able": false } },
        [
          _c(
            "section",
            {
              staticClass:
                "d-flex flex-column align-items-center justify-content-center",
            },
            [
              _c(
                "span",
                {
                  staticClass: "text-center mb-2 d-block f15 font-weight-bold",
                },
                [_vm._v(_vm._s(_vm.$t("Call")))]
              ),
              _c(
                "span",
                { staticClass: "text-center mb-2 d-block f13 primary--text" },
                [_vm._v(_vm._s(_vm.clinic.contactDescription))]
              ),
              _vm.clinic.phone
                ? _c(
                    "v-chip-group",
                    { attrs: { column: "" } },
                    _vm._l(
                      (_vm.clinic.phone || "").split(" , "),
                      function (phone) {
                        return _c(
                          "v-chip",
                          {
                            staticClass: "my-1 ltr",
                            attrs: {
                              color: "primary",
                              link: "",
                              href: `tel:${phone}`,
                            },
                          },
                          [
                            _c("v-icon", { attrs: { right: "" } }, [
                              _vm._v(" mdi-phone "),
                            ]),
                            _vm._v(" " + _vm._s(phone) + " "),
                          ],
                          1
                        )
                      }
                    ),
                    1
                  )
                : _vm._e(),
              _vm.clinic.address
                ? _c("p", {
                    staticClass: "f14 my-2 px-2",
                    domProps: { innerHTML: _vm._s(_vm.clinic.address) },
                  })
                : _vm._e(),
              _vm.clinic.lat && _vm.clinic.lon
                ? _c(
                    "mapir",
                    {
                      staticClass: "rounded-xl overflow-hidden",
                      staticStyle: { height: "250px" },
                      attrs: {
                        apiKey: _vm.mapirToken,
                        center: _vm.coordinates,
                        zoom: 13,
                      },
                    },
                    [
                      _c("mapMarker", {
                        attrs: {
                          coordinates: _vm.markerCoordinates,
                          color: "blue",
                          draggable: true,
                        },
                        on: {
                          "update:coordinates": function ($event) {
                            _vm.markerCoordinates = $event
                          },
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _c("p", {
                staticClass:
                  "f14 my-2 px-4 text-center border border-accent red--text text--darken-1 rounded-xl lh-22",
                domProps: {
                  textContent: _vm._s(
                    _vm.$t(
                      "In critical situations, such as any child abuse, spousal abuse or suicidal thoughts, call 123 Social Emergency"
                    )
                  ),
                },
              }),
              _c(
                "v-btn",
                {
                  staticClass: "mb-2",
                  attrs: { block: "", color: "primary", rounded: "" },
                  on: { click: _vm.closeCallBottomSheet },
                },
                [_vm._v(_vm._s(_vm.$t("Close")))]
              ),
            ],
            1
          ),
        ]
      ),
      _c("BshDoctor", { ref: "drBottomSheet", attrs: { fromDoctorApp: true } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }